export enum SELECTOR {
    KLAVIYO_INPUT = '.klaviyo-form input'
}

export enum DOM_EVENT {
    BLUR = 'blur',
    KEYUP = 'keyup'
}

export const CLASS_NAME = {
    SHADOW_CONTAINER: 'ot-shadow-container'
};
