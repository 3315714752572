import { callAPI } from './api';

import { HTTP_METHOD } from '@onetext/api';

type ShopifyCartResponse = {
    token : string,
    items : ReadonlyArray<{
        variant_id : string,
        quantity : number,
    }>,
};

export const getShopifyCart = async () : Promise<ShopifyCartResponse> => {
    const {
        body
    } = await callAPI<ShopifyCartResponse>({
        method: HTTP_METHOD.GET,
        url:    `${ window.Shopify?.routes?.root ?? '/' }cart.js`
    });

    return body;
};

export const getOrCreateShopifyCart = async () : Promise<ShopifyCartResponse> => {
    const {
        body
    } = await callAPI<ShopifyCartResponse>({
        method: HTTP_METHOD.POST,
        url:    `${ window.Shopify?.routes?.root ?? '/' }cart.js`
    });

    return body;
};

