import { initializeCustomer } from './biz';
import { SDK_ATTRIBUTE } from './constants';
import { initializeIntegrations } from './integrations';
import {
    addLoggerPayloadBuilder, disableHardErrorLogs, getSDKAuthUsername, getSDKUrl, hotReloadPageOnSDKUpdate,
    IntegrationError, isBot, isDevelopment, isIncompatibleBrowser, track,
    trackError
} from './lib';

export * from './index';

const triggerOneTextLoad = () : void => {
    track('onetext_sdk_load');

    if (isIncompatibleBrowser()) {
        track('sdk_init_abort_incompatible_browser');
        return;
    }

    addLoggerPayloadBuilder(() => {
        return {
            sdkURL: getSDKUrl()
        };
    });

    const existingOneTextLoad = window.onLoadOneText ??= [];

    const load = (loader : OneTextLoader) : void => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (typeof loader !== 'object' || loader === null) {
            const err = new Error(`Child SDK passed invalid loader: ${ typeof loader }`);

            trackError(err, {
                type:      'child_sdk_loader_invalid',
                hardError: false
            });

            return;
        }

        setTimeout(() => {
            void loader.loader();
        }, 0);

        if (loader.name && loader.commitHash) {
            addLoggerPayloadBuilder(() => {
                return {
                    childSDKName:       loader.name,
                    childSDKCommitHash: loader.commitHash
                };
            });

            track('onetext_child_sdk_load');

            if (loader.commitHash !== sdk.commitHash) {
                disableHardErrorLogs();

                const err = new Error(`Child SDK commit hash mismatch. Expected ${ sdk.commitHash }, got ${ loader.commitHash }`);

                trackError(err, {
                    type:      'child_sdk_commit_hash_mismatch',
                    hardError: false
                });
            }
        }
    };

    window.onLoadOneText = {
        length: 0,
        push:   (...loaders) => {
            for (const loader of loaders) {
                load(loader);
            }

            return loaders.length;
        }
    };

    for (const loader of Array.from(existingOneTextLoad)) {
        load(loader);
    }
};

const initialize = async () : Promise<void> => {
    try {
        if (isBot()) {
            track('sdk_init_abort_bot');
            return;
        }

        if (!getSDKAuthUsername()) {
            throw new IntegrationError({
                message: `Can not initialize SDK without ${ SDK_ATTRIBUTE.ACCOUNT_TOKEN } attribute`
            });
        }

        await Promise.all([
            triggerOneTextLoad(),
            initializeIntegrations(),
            initializeCustomer(),
            isDevelopment()
                ? hotReloadPageOnSDKUpdate()
                : undefined
        ]);
    } catch (err) {
        trackError(err, {
            type:      'sdk_init',
            hardError: false
        });

        if (err instanceof IntegrationError) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }
};

void initialize();
