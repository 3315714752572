/** @jsx h */
import type { VNode } from 'preact';

import { h } from 'preact';

export const ConsentPopupVerticalSpacer = () : VNode => {
    return (
        <div
            className={ `mt-2` }
        />
    );
};
