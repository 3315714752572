/** @jsx h */

import type { VNode } from 'preact';

import clsx from 'clsx';
import { h } from 'preact';
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks';

import type { FieldStyle } from '../../../types';

import { identity } from '../../../lib';
import { useConsentFormContext, useConsentPopupContext } from '../context';

type ConsentPopupTextFieldProps<Type extends string> = {
    type ?: string,
    id ?: string,
    name ?: string,
    autocomplete ?: string,
    placeholder ?: string,
    autofocus ?: boolean,
    defaultValue ?: Type,
    onValue ?: (value : Type | undefined) => void,
    onAutoComplete ?: () => void,
    inputPattern ?: RegExp,
    required ?: boolean,
    isValid ?: (value : string) => boolean,
    deformatter ?: (value : string) => string,
    maxLength ?: number,
    className ?: string,
};

const defaultIsValid = () : boolean => true;

export const ConsentPopupTextField = <Type extends string>({
    type,
    id,
    name,
    autocomplete,
    placeholder,
    autofocus = false,
    defaultValue,
    onAutoComplete,
    onValue,
    inputPattern,
    required = true,
    isValid = defaultIsValid,
    deformatter = identity,
    maxLength,
    className
} : ConsentPopupTextFieldProps<Type>) : VNode => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const formContext = useConsentFormContext();
    const [ currentValue, setCurrentValue ] = useState<string | undefined>(defaultValue);
    const [ priorCurrentValue, setPriorCurrentValue ] = useState<string | undefined>(defaultValue);
    const { brandConfig } = useConsentPopupContext();
    const fieldStyle : FieldStyle = brandConfig?.field?.style ?? {};

    const isValueValid = useCallback((value : string | undefined) : boolean => {
        if (required && !value) {
            return false;
        }

        if (value && !isValid(value)) {
            return false;
        }

        if (maxLength && value && value.length > maxLength) {
            return false;
        }

        return true;
    }, []);

    const isCurrentValueValid = useMemo(() => {
        return isValueValid(currentValue);
    }, [ currentValue, isValid ]);

    useEffect(() => {
        const registeredField = formContext.registerField({
            isValid: () => isCurrentValueValid
        });

        return registeredField.unregister;
    }, [ formContext, isCurrentValueValid ]);

    useEffect(() => {
        setPriorCurrentValue(currentValue);

        if (
            priorCurrentValue === undefined &&
            currentValue &&
            currentValue.length >= 3 &&
            isCurrentValueValid &&
            autocomplete &&
            onAutoComplete
        ) {
            onAutoComplete();
        }
    }, [ currentValue, isCurrentValueValid, priorCurrentValue, autocomplete, onAutoComplete ]);

    const focus = useCallback(() : void => {
        const input = inputRef.current;

        if (input) {
            // if not focused, focus
            if (!document.activeElement?.isSameNode(input)) {
                input.focus({
                    preventScroll: true
                });

                const rect = input.getBoundingClientRect();
                const scrollTo = rect.top - 200;

                window.scrollTo({
                    top:      scrollTo,
                    behavior: 'smooth'
                });
            }
        }
    }, [ inputRef.current ]);

    useEffect(() => {
        if (autofocus) {
            focus();
            setTimeout(focus, 10);
        }
    }, [ autofocus, focus ]);

    const updateCurrentValue = (value : string | undefined) : void => {
        setCurrentValue(value);

        if (onValue) {
            onValue(
                isValueValid(value)
                    ? value as Type
                    : undefined
            );
        }
    };

    const onKeyDown = (event : KeyboardEvent) : void => {
        const input = event.currentTarget as HTMLInputElement;

        if (event.currentTarget && event.key) {
            const modifierKey = (
                event.metaKey ||
                event.ctrlKey ||
                event.altKey
            );

            if (event.key.length === 1 && !modifierKey) {
                const newValue = input.value + event.key;

                if (inputPattern && !inputPattern.test(newValue)) {
                    event.preventDefault();

                }
            } else if (event.key === 'Enter') {
                void formContext.submit();
            }
        }
    };

    const onInput = (event : Event) : void => {
        const input = event.currentTarget as HTMLInputElement;
        const value = deformatter(input.value).slice(0, maxLength);

        updateCurrentValue(value || undefined);
        input.value = value;
    };

    return (
        <input
            id={ id }
            name={ name }
            autocomplete={ autocomplete }
            ref={ inputRef }
            type={ type }
            placeholder={ placeholder }
            class={
                clsx(
                    `border rounded py-2 px-2.5 min-w-[200px] w-full h-12 focus:outline-none text-black`,
                    isCurrentValueValid || !formContext.displayValidity
                        ? 'border-slate-300'
                        : 'border-red-500',
                    className
                )
            }
            style={ fieldStyle }
            value={ currentValue }
            onInput={ onInput }
            onKeyDown={ onKeyDown }
        />
    );
};
