/* @flow */

import { noop } from './util';

type EventEmitterOptions<Payload> = {
    onError ?: (opts : { payload : Payload, error : unknown }) => Promise<void> | void,
};

type Listener<Payload> = (payload : Payload) => Promise<void> | void;

export type EventEmitter<Payload> = {
    listen : (listener : Listener<Payload>) => { cancel : () => void },
    emit : (payload : Payload) => void,
};

export const eventEmitter = <Payload>({
    onError = noop
} : EventEmitterOptions<Payload> = {}) : EventEmitter<Payload> => {
    const listeners = new Set<Listener<Payload>>();

    const listen = (listener : Listener<Payload>) : { cancel : () => void } => {
        listeners.add(listener);
        return {
            cancel: () => {
                listeners.delete(listener);
            }
        };
    };

    const emit = (payload : Payload) : void => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        listeners.forEach(async (listener) : Promise<void> => {
            try {
                await listener(payload);
            } catch (error) {
                await onError({
                    payload,
                    error
                });
            }
        });
    };

    return {
        listen,
        emit
    };
};
