/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';

import type { ConsentFormContextType, FormField } from '../context';

import { delay, openKeyboard, promiseTry } from '../../../lib';
import { useDialogContext } from '../../dialog';
import { ConsentFormContext } from '../context';

import type { Milliseconds } from '@onetext/api';

type ConsentPopupFormProps = {
    children ?: ComponentChildren,
    onSubmit : () => Promise<void> | void,
    onSubmitStart ?: () => Promise<void> | void,
    submitDelay ?: Milliseconds,
    openKeyboardOnSubmit : boolean,
};

export const ConsentPopupForm = ({
    children,
    onSubmit,
    onSubmitStart,
    submitDelay,
    openKeyboardOnSubmit
} : ConsentPopupFormProps) : VNode => {
    const [ displayValidity, setDisplayValidity ] = useState(false);
    const [ fields, setFields ] = useState<Set<FormField>>(new Set());
    const [ loading, setLoading ] = useState(false);
    const dialog = useDialogContext();

    const isValid = useCallback(() : boolean => {
        for (const field of fields) {
            if (!field.isValid()) {
                return false;
            }
        }

        return true;
    }, [ fields ]);

    const attemptSubmit = useCallback(() : boolean => {
        if (!isValid()) {
            setDisplayValidity(true);
            return false;
        }

        return true;
    }, [ isValid, setDisplayValidity ]);

    const submit = useCallback(() : Promise<void> => {
        setLoading(true);

        return promiseTry(async () => {
            if (!attemptSubmit()) {
                return;
            }

            if (openKeyboardOnSubmit) {
                openKeyboard({
                    inputContainer: dialog?.element
                });
            }

            await onSubmitStart?.();

            if (submitDelay) {
                await delay(submitDelay);
            }

            return onSubmit();
        }).finally(() => {
            setLoading(false);
        });
    }, [ fields, setDisplayValidity, onSubmit ]);

    const registerField = useCallback((field : FormField) => {
        setFields(existingFields => {
            existingFields.add(field);
            return existingFields;
        });

        return {
            unregister: () => {
                setFields(existingFields => {
                    existingFields.delete(field);
                    return existingFields;
                });
            }
        };
    }, [ setFields ]);

    const context : ConsentFormContextType = useMemo(() => {
        return {
            submit,
            attemptSubmit,
            registerField,
            loading,
            displayValidity
        };
    }, [ submit, attemptSubmit, registerField, loading, displayValidity ]);

    return (
        <ConsentFormContext.Provider value={ context }>
            { children }
        </ConsentFormContext.Provider>
    );
};
