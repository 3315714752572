/* @flow */
import { eventEmitter, noop } from '../lib';

type UserIdentifiedEventPayload = {
    accountToken : string,
};

export const userIdentifiedEvent = eventEmitter<UserIdentifiedEventPayload>({
    onError: noop
});
