/** @jsx h */
import type { ComponentChildren, JSX } from 'preact';

import { h } from 'preact';

type ConsentPopupLayoutProps = {
    children : ComponentChildren,
    className ?: string,
    style ?: JSX.CSSProperties,
};

const defaultStyle : JSX.CSSProperties = {};

export const ConsentPopupRow = ({
    children,
    className = '',
    style = defaultStyle
} : ConsentPopupLayoutProps) : JSX.Element => (
    <div
        class={ `flex flex-col items-center justify-center h-full ${ className }` }
        style={ style }>
        { children }
    </div>
);

export const ConsentPopupColumns = ({
    children,
    className = '',
    style = defaultStyle
} : ConsentPopupLayoutProps) : JSX.Element => (
    <div
        class={ `grid md:grid-cols-[400px_400px] h-full gap-16 ${ className }` }
        style={ style }>
        { children }
    </div>
);

export const ConsentPopupColumn = ({
    children,
    className = '',
    style = defaultStyle
} : ConsentPopupLayoutProps) : JSX.Element => (
    <div
        class={ `col-span-1 flex flex-col items-center justify-center ${ className }` }
        style={ style }>
        { children }
    </div>
);
