/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';

import { parsePhone, removePhoneCountryCode } from '../../../lib';
import { useConsentFormContext, useConsentPopupContext } from '../context';

import { ConsentPopupTextField } from './text-field';

type ConsentPopupPhoneFieldProps = {
    autofocus ?: boolean,
    placeholder ?: string,
    submitOnAutoComplete ?: boolean,
    validateAreaCode ?: boolean,
    className ?: string,
};

export const ConsentPopupPhoneField = ({
    autofocus,
    placeholder = 'Phone Number',
    submitOnAutoComplete = true,
    validateAreaCode = false,
    className
} : ConsentPopupPhoneFieldProps) : VNode => {
    const {
        setPhone,
        phone
    } = useConsentPopupContext();

    const {
        submit
    } = useConsentFormContext();

    const defaultLocalPhone = phone
        ? removePhoneCountryCode(phone)
        : undefined;

    const onNewLocalPhone = (newLocalPhone : string | undefined) : void => {
        const newPhone = newLocalPhone
            ? parsePhone(newLocalPhone, {
                validateAreaCode
            })
            : undefined;

        setPhone(
            newPhone
                ? newPhone
                : undefined
        );
    };

    const isValidLocalPhone = (newLocalPhone : string) : boolean => {
        return Boolean(parsePhone(newLocalPhone, {
            validateAreaCode
        }));
    };

    const onAutoComplete = () : void => {
        if (submitOnAutoComplete) {
            void submit();
        }
    };

    const deformatLocalPhone = (newPartialLocalPhone : string) : string => {
        return newPartialLocalPhone.replace(/\D+/g, '').replace(/^[01]/, '');
    };

    return (
        <ConsentPopupTextField
            type={ 'tel' }
            id={ 'phone' }
            name={ 'phone' }
            autocomplete={ 'tel' }
            autofocus={ autofocus }
            placeholder={ placeholder }
            defaultValue={ defaultLocalPhone }
            onValue={ onNewLocalPhone }
            onAutoComplete={ onAutoComplete }
            inputPattern={ /^\d*$/ }
            required={ true }
            isValid={ isValidLocalPhone }
            deformatter={ deformatLocalPhone }
            maxLength={ 10 }
            className={ className }
        />
    );
};
