/* @flow */

import type { CallAPIResult } from './api';

import { callOneTextAPI } from './api';

import type { PostShopifyBrowseAPI } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

export const sendShopifyBrowseWebhook = (
    body : PostShopifyBrowseAPI['request']
) : Promise<CallAPIResult<PostShopifyBrowseAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'shopify/webhook/client/browse',
        body
    });
};
