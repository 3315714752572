// eslint-disable-next-line import/no-unassigned-import
import './styles.css';

import { createStyleSheetElement, getCurrentScriptBasePath, insertStyleSheetElement } from '../lib';

const getSDKStyleSheetElement = () : HTMLLinkElement => {
    return createStyleSheetElement(`${ getCurrentScriptBasePath() }${ sdk.cssFileName }`);
};

type InsertSDKStyleSheetElementOptions = {
    container : Element | ShadowRoot,
};

export const insertSDKStyleSheetElement = async ({
    container
} : InsertSDKStyleSheetElementOptions) : Promise<void> => {
    await insertStyleSheetElement({
        element: getSDKStyleSheetElement(),
        container
    });
};
