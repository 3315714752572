/* @jsx h */

import type { VNode } from 'preact';

import { Component } from 'preact';

import { trackError } from '../lib';

type ErrorBoundaryProps = {
    children : VNode,
};

export class ErrorBoundary extends Component<ErrorBoundaryProps> {
    componentDidCatch(err : unknown) : void {
        trackError(err, {
            type: 'sdk_component_render'
        });
    }

    render() : VNode {
        const { children } = this.props;
        return children;
    }
}

