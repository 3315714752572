import { Logger } from 'beaver-logger';

import { getStoredAccountToken } from '../biz';

import { stringifyError, UnhandledError } from './error';
import { getAPIDomain, getSDKAccountToken, getSDKAuthHeader, getSDKClientID, isDevelopment } from './sdk';

import type { JSONObject } from '@onetext/api';
import { EVENT, HTTP_HEADER } from '@onetext/api';

type Payload = {
    [key : string] : unknown,
};

const ENABLE_LOGGER = isDevelopment()
    ? false
    : true;

const loadTime = Date.now();

const logger = Logger({
    url:           `${ getAPIDomain() }/api/actions`,
    flushInterval: 10 * 1000
});

logger.addHeaderBuilder(() => {
    const headers : { [ key in HTTP_HEADER ] ?: string } = {};

    const authHeader = getSDKAuthHeader();

    if (authHeader) {
        headers[ HTTP_HEADER.AUTHORIZATION ] = authHeader;
    }

    return headers;
});

logger.addPayloadBuilder(() => {
    return {
        hostAccountToken:     getSDKAccountToken(),
        hostClientToken:      getSDKClientID(),
        customerAccountToken: getStoredAccountToken(),
        sdkCommitHash:        sdk.commitHash,
        host:                 window.location.host,
        url:                  window.location.href,
        loadTime,
        timeSinceLoad:        Date.now() - loadTime
    };
});

export const addLoggerPayloadBuilder = (builder : (payload : Payload) => Payload) : void => {
    logger.addPayloadBuilder(builder);
};

export const track = (event : string, payload ?: JSONObject) : void => {
    if (!ENABLE_LOGGER) {
        // eslint-disable-next-line no-console
        console.info(event, payload);
        return;
    }

    void logger.info(event, payload).flush();
};

let hardErrorLogsEnabled = true;

export const disableHardErrorLogs = () : void => {
    hardErrorLogsEnabled = false;
};

type TrackErrorOptions = {
    hardError ?: boolean,
    type : string,
    payload ?: JSONObject,
};

export const trackError = (err : unknown, {
    payload = {},
    hardError = hardErrorLogsEnabled
        ? err instanceof UnhandledError
            ? err.hardError
            : true
        : false,
    type
} : TrackErrorOptions) : void => {
    if (!ENABLE_LOGGER) {
        // eslint-disable-next-line no-console
        console.error(err);
        return;
    }

    const eventName = hardError
        ? EVENT.CLIENT_UNHANDLED_ERROR
        : EVENT.CLIENT_SOFT_UNHANDLED_ERROR;

    void logger.error(eventName, {
        ...payload,
        error:     stringifyError(err),
        type,
        hardError,
        errorData: err instanceof UnhandledError
            ? err.data
            : undefined
    });
};
