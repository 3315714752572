export const createStyleSheetElement = (url : string) : HTMLLinkElement => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = url;
    return link;
};

type InsertStyleSheetElementOptions = {
    element : HTMLLinkElement,
    container ?: Element | ShadowRoot,
};

export const insertStyleSheetElement = ({
    element,
    container = document.head
} : InsertStyleSheetElementOptions) : Promise<HTMLLinkElement> => {
    return new Promise((resolve, reject) => {
        element.addEventListener('load', () => resolve(element));
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        element.addEventListener('error', (err) => reject(err));
        container.appendChild(element);
    });
};

