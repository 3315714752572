import { getBody, getUserAgent } from '../util';

export const getURLQuery = () : { [ key : string ] : string } => {
    const queryString = new URLSearchParams(window.location.search);
    return Object.fromEntries(queryString.entries());
};

export const getURLQueryParam = (name : string) : string | undefined => {
    const queryParams = getURLQuery();
    return queryParams[name];
};

let openKeyboardInput : HTMLInputElement | undefined;

type OpenKeyboardOptions = {
    inputContainer ?: HTMLElement,
    inputVisible ?: boolean,
};

export const openKeyboard = ({
    inputContainer,
    inputVisible = false
} : OpenKeyboardOptions = {}) : void => {
    if (openKeyboardInput?.parentElement) {
        openKeyboardInput.parentElement.removeChild(openKeyboardInput);
    }

    openKeyboardInput = document.createElement('input');

    if (!inputVisible) {
        openKeyboardInput.style.position = 'fixed';
        openKeyboardInput.style.visibility = 'invisible';
        openKeyboardInput.style.opacity = '0';
        openKeyboardInput.style.pointerEvents = 'none';
        openKeyboardInput.style.top = `${ window.scrollY + Math.round(window.innerHeight / 2) }px`;
        openKeyboardInput.style.left = `-10000px`;
    }

    const finalContainer = (
        inputContainer ??
        getBody()
    );

    finalContainer.appendChild(openKeyboardInput);

    openKeyboardInput.focus({
        preventScroll: true
    });
};

export const isBot = () : boolean => {
    const userAgent = getUserAgent();

    if (!userAgent) {
        return false;
    }

    return (/bot|crawl|spider|wget|slurp|mediapartners-google/i).test(userAgent);
};
