// Set up preact context

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

export type FormField = {
    isValid : () => boolean,
};

type RegisterField = (opts : FormField) => {
    unregister : () => void,
};

export type ConsentFormContextType = {
    displayValidity : boolean,
    registerField : RegisterField,
    attemptSubmit : () => boolean,
    loading : boolean,
    submit : () => Promise<void> | void,
};

export const ConsentFormContext = createContext<ConsentFormContextType | undefined>(undefined);

export const useConsentFormContext = () : ConsentFormContextType => {
    const context = useContext(ConsentFormContext);

    if (!context) {
        throw new Error('Can not find consent form context');
    }

    return context;
};
