import { identifyCustomer } from '../biz';
import { QUERY_PARAM, SELECTOR } from '../constants';
import { awaitBody, listenForPhoneInput } from '../lib';

const associateAccountFromKlaviyoID = async () : Promise<void> => {
    const queryString = new URLSearchParams(window.location.search);
    const klaviyoID = queryString.get(QUERY_PARAM.KLAVIYO_IDENTIFIER);

    if (klaviyoID) {
        await identifyCustomer({
            klaviyoID
        });
    }
};

const listenForKlaviyoPhoneInput = async () : Promise<void> => {
    const body = await awaitBody();

    listenForPhoneInput({
        selector:    SELECTOR.KLAVIYO_INPUT,
        rootElement: body,
        handler:     ({ phone }) => {
            void identifyCustomer({
                phone
            });
        }
    });
};

export const initializeKlaviyoIntegration = async () : Promise<void> => {
    await Promise.all([
        listenForKlaviyoPhoneInput(),
        associateAccountFromKlaviyoID()
    ]);
};
