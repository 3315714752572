import {
    generateCoreFlowLink, generateCustomFlowLink, getBasicSwitchToSMSThreadLink, getSwitchToSMSThreadLink,
    sendCoreFlowStartMessage, sendCustomFlowStartMessage, startCoreFlow, startCustomFlow, switchToSMSThread
} from '../../api';
import { renderComponent } from '../../component';
import { LinkModal } from '../../components';
import { isDevice } from '../../lib';
import { identifyCustomer } from '../customer';

import type { FLOW_RECEIVERS_TYPE, JSONObject, PhoneNumber } from '@onetext/api';
import { CORE_FLOW_TYPE } from '@onetext/api';

type LaunchFlowOptions = {
    externalID ?: string,
    coreFlowType ?: CORE_FLOW_TYPE.WELCOME,
    phoneNumber ?: PhoneNumber,
    email ?: string,
    incomingMessageText ?: string,
    parameters ?: JSONObject,
    allowAutoThreadSwitch ?: boolean,
    allowModal ?: boolean,
    followQuietHours ?: boolean,
};

const launchFlow = async ({
    phoneNumber,
    email,
    coreFlowType,
    externalID,
    parameters = {},
    incomingMessageText,
    allowAutoThreadSwitch = false,
    allowModal = false,
    followQuietHours = true
} : LaunchFlowOptions) : Promise<void> => {
    if (!coreFlowType && !externalID) {
        throw new Error(`Expected coreFlowType or externalID`);
    }

    const isMobile = isDevice();
    const container = 'body';
    const title = `Continue with a text`;
    const subTitleSwitchMobile = `Head over to your phone's messaging or sms app to continue`;
    const subTitleSwitchDesktop = `Head over to your phone's messaging or sms app to continue, or scan:`;
    const buttonText = `Take me there`;
    const showButton = isMobile;
    const showQR = !isMobile;
    const forceSMSRedirect = isMobile;

    const sendFlowToPhone = async () : Promise<void> => {
        if (!phoneNumber) {
            throw new Error(`Expected phoneNumber`);
        }

        if (coreFlowType) {
            await startCoreFlow({
                receivers: {
                    type:     'contacts' as FLOW_RECEIVERS_TYPE.CONTACTS,
                    contacts: [
                        {
                            phoneNumber,
                            email
                        }
                    ]
                },
                coreFlowType,
                customFlowParameters: parameters,
                followQuietHours
            });
        } else if (externalID) {
            await startCustomFlow({
                receivers: {
                    type:     'contacts' as FLOW_RECEIVERS_TYPE.CONTACTS,
                    contacts: [ {
                        phoneNumber,
                        email
                    } ]
                },
                externalID,
                parameters,
                followQuietHours
            });
        } else {
            throw new Error(`Expected coreFlowType or externalID`);
        }
    };

    const launchFlowStartMessage = () : Promise<void> => {
        if (coreFlowType) {
            return sendCoreFlowStartMessage({
                message:              incomingMessageText,
                coreFlowType,
                customFlowParameters: parameters,
                forceSMSRedirect
            });
        } else if (externalID) {
            return sendCustomFlowStartMessage({
                message: incomingMessageText,
                externalID,
                parameters,
                forceSMSRedirect
            });
        } else {
            throw new Error(`Expected coreFlowType or externalID`);
        }
    };

    const associate = async () : Promise<void> => {
        if (phoneNumber) {
            await identifyCustomer({
                phone: phoneNumber
            });
        }
    };

    const createFlowStartMessageURL = () : Promise<string> => {
        if (coreFlowType) {
            return generateCoreFlowLink({
                message:              incomingMessageText,
                coreFlowType,
                customFlowParameters: parameters,
                forceSMSRedirect,
                raw:                  !showQR
            });
        } else if (externalID) {
            return generateCustomFlowLink({
                message: incomingMessageText,
                externalID,
                parameters,
                forceSMSRedirect,
                raw:     !showQR
            });
        } else {
            throw new Error(`Expected coreFlowType or externalID`);
        }
    };

    type RenderModalOptions = {
        url : () => Promise<string> | string,
    };

    const renderModal = ({
        url
    } : RenderModalOptions) : void => {
        renderComponent({
            component: LinkModal,
            props:     {
                url,
                title,
                subTitle: isMobile
                    ? subTitleSwitchMobile
                    : subTitleSwitchDesktop,
                showButton,
                buttonText,
                showQR
            },
            container,
            shadowDOM:  false,
            newElement: true
        });
    };

    const renderFlowStartModal = () : void => {
        renderModal({
            url: createFlowStartMessageURL
        });
    };

    const renderThreadSwitchModal = () : void => {
        renderModal({
            url: async () => {
                if (showQR) {
                    const { body } = await getSwitchToSMSThreadLink();
                    return body.link;
                } else {
                    return getBasicSwitchToSMSThreadLink();
                }
            }
        });
    };

    if (phoneNumber) {
        await Promise.all([
            sendFlowToPhone(),
            associate(),
            allowAutoThreadSwitch && isMobile
                ? switchToSMSThread()
                : undefined,
            allowModal
                ? renderThreadSwitchModal()
                : undefined
        ]);
    } else if (isMobile) {
        if (!allowAutoThreadSwitch && !allowModal) {
            throw new Error(`Can not start flow without auto thread switch or modal`);
        }

        await Promise.all([
            allowAutoThreadSwitch
                ? launchFlowStartMessage()
                : undefined,
            allowModal
                ? renderFlowStartModal()
                : undefined
        ]);
    } else {
        if (!allowModal) {
            throw new Error(`Can not start flow without modal`);
        }

        renderFlowStartModal();
    }
};

type LaunchCustomFlowOptions = {
    externalID : string,
    phoneNumber ?: PhoneNumber,
    email ?: string,
    incomingMessageText ?: string,
    parameters ?: JSONObject,
    allowAutoThreadSwitch ?: boolean,
    allowModal ?: boolean,
    followQuietHours ?: boolean,
};

export const launchCustomFlow = ({
    externalID,
    phoneNumber,
    email,
    incomingMessageText,
    parameters,
    allowAutoThreadSwitch,
    allowModal,
    followQuietHours = true
} : LaunchCustomFlowOptions) : Promise<void> => {
    if (!externalID) {
        throw new Error(`Expected externalID`);
    }

    return launchFlow({
        externalID,
        phoneNumber,
        email,
        incomingMessageText,
        parameters,
        allowAutoThreadSwitch,
        allowModal,
        followQuietHours
    });
};

type LaunchCoreFlowOptions = {
    type : CORE_FLOW_TYPE.WELCOME,
    phoneNumber ?: PhoneNumber,
    email ?: string,
    incomingMessageText ?: string,
    customFlowParameters ?: JSONObject,
    allowAutoThreadSwitch ?: boolean,
    allowModal ?: boolean,
    followQuietHours ?: boolean,
    marketingConsentGranted ?: boolean,
};

export const launchCoreFlow = ({
    type,
    phoneNumber,
    email,
    incomingMessageText,
    customFlowParameters,
    allowAutoThreadSwitch,
    allowModal,
    followQuietHours = true
} : LaunchCoreFlowOptions) : Promise<void> => {
    return launchFlow({
        coreFlowType: type,
        phoneNumber,
        email,
        incomingMessageText,
        parameters:   customFlowParameters,
        allowAutoThreadSwitch,
        allowModal,
        followQuietHours
    });
};

export const coreFlowTypes = {
    welcome: CORE_FLOW_TYPE.WELCOME
};
