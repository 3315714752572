/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';
import QRC from 'qrcode-svg';

type QRCodeProps = {
    url : string,
    size : number,
};

export const QRCode = ({
    url,
    size
} : QRCodeProps) : VNode => {
    return (
        <a
            href={ url }
            ref={
                element => {
                    if (element) {
                        const qr = new QRC({
                            content:    url,
                            padding:    0,
                            width:      size,
                            height:     size,
                            color:      '#000000',
                            background: '#ffffff'
                        });
                        element.innerHTML = qr.svg();
                    }
                }
            } />
    );
};
