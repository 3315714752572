// Set up preact context

import { createContext } from 'preact';
import { useContext } from 'preact/hooks';

type ConsentQuizContextType = {
    submitQuizOption : (opts : {
        propertyValue : string,
    }) => Promise<void> | void,
};

export const ConsentQuizContext = createContext<ConsentQuizContextType | undefined>(undefined);

export const useConsentQuizContext = () : ConsentQuizContextType => {
    const context = useContext(ConsentQuizContext);

    if (!context) {
        throw new Error('Can not find consent quiz context');
    }

    return context;
};

