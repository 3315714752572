import type { CallAPIResult } from './api';

import { callOneTextAPI } from './api';

import type { PostOptInAPI } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

export const submitOptIn = (
    body : PostOptInAPI['request']
) : Promise<CallAPIResult<PostOptInAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'optin',
        body
    });
};
