import type { CallAPIResult } from '../api';

import { getSDKAccountToken, getSDKClientID, isDevice, promiseTry } from '../../lib';
import { callOneTextAPI, constructOneTextURL, redirectInPopup } from '../api';

import type { HTTPUrl, PostFlowLinkAPI, PostFlowStartAutomationAPI, SMSUrl } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

export const startCustomFlow = (
    body : PostFlowStartAutomationAPI['request']
) : Promise<CallAPIResult<PostFlowStartAutomationAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'flow/start',
        body
    });
};

export const getCustomFlowLink = (
    body : PostFlowLinkAPI['request']
) : Promise<CallAPIResult<PostFlowLinkAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'flow/link',
        body
    });
};

type GetRawCustomFlowLinkOptions = {
    message ?: string,
    email ?: string,
    externalID : string,
    parameters ?: Record<string, unknown>,
    forceSMSRedirect ?: boolean,
};

export const getRawCustomFlowLink = ({
    externalID,
    message,
    parameters,
    forceSMSRedirect = false
} : GetRawCustomFlowLinkOptions) : HTTPUrl => {
    const accountToken = getSDKAccountToken();
    const clientID = getSDKClientID();

    if (!accountToken && !clientID) {
        throw new Error(`Can not determine clientID`);
    }

    const query : Record<string, string> = {
        externalID,
        domain: window.location.origin
    };

    if (parameters) {
        query.parameters = JSON.stringify(parameters);
    }

    if (message) {
        query.message = message;
    }

    if (accountToken) {
        query.accountToken = accountToken;
    }

    if (clientID) {
        query.clientID = clientID;
    }

    if (forceSMSRedirect) {
        query.forceSMSRedirect = 'true';
    }

    return constructOneTextURL({
        path: 'flow/start',
        query
    });
};

type GenerateCustomFlowLinkOptions = {
    message ?: string,
    email ?: string,
    externalID : string,
    parameters ?: JSONObject,
    forceSMSRedirect ?: boolean,
    raw ?: boolean,
};

export const generateCustomFlowLink = async ({
    message,
    email,
    externalID,
    parameters,
    forceSMSRedirect,
    raw
} : GenerateCustomFlowLinkOptions) : Promise<HTTPUrl | SMSUrl> => {
    if (raw) {
        return getRawCustomFlowLink({
            message,
            email,
            externalID,
            parameters,
            forceSMSRedirect
        });
    }

    const { body } = await getCustomFlowLink({
        message,
        externalID,
        parameters,
        forceSMSRedirect
    });

    return body.link;
};

type SendCustomFlowStartMessageOptions = {
    message ?: string,
    email ?: string,
    externalID : string,
    parameters ?: Record<string, unknown>,
    forceSMSRedirect ?: boolean,
};

export const sendCustomFlowStartMessage = ({
    externalID,
    message,
    parameters,
    forceSMSRedirect = false
} : SendCustomFlowStartMessageOptions) : Promise<void> => {
    return promiseTry(() => {
        if (isDevice()) {
            return redirectInPopup({
                url: getRawCustomFlowLink({
                    externalID,
                    message,
                    parameters,
                    forceSMSRedirect
                })
            });
        }
    });
};

export const sendFlowStartMessage = sendCustomFlowStartMessage;
