import type { CallAPIResult } from './api';

import { callOneTextAPI } from './api';

import type { PostAssociateAPI, PostCustomerPropertiesAPI } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

export const associate = (
    body : PostAssociateAPI['request']
) : Promise<CallAPIResult<PostAssociateAPI['response']>> => {
    return callOneTextAPI({
        method:                                HTTP_METHOD.POST,
        path:                                  'associate',
        body,
        allowedRetriesOnResponseBodyReadError: 3
    });
};

export const addCustomerProperties = (
    body : PostCustomerPropertiesAPI['request']
) : Promise<CallAPIResult<PostCustomerPropertiesAPI['response']>> => {
    return callOneTextAPI({
        method:                                HTTP_METHOD.POST,
        path:                                  'customer/properties/set',
        body,
        allowedRetriesOnResponseBodyReadError: 3
    });
};
