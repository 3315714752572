import type { ButtonColors } from '../types';

export const DEFAULT_BUTTON_COLORS : ButtonColors = {
    primary: {
        text: {
            base:  '#ffffff',
            hover: '#ffffff'
        },
        background: {
            base:  '#2563eb',
            hover: '#1d4ed8'
        }
    },
    secondary: {
        text: {
            base:  '#1d4ed8',
            hover: '#1d4ed8'
        },
        background: {
            base:  '#dbeafe',
            hover: '#bfdbfe'
        }
    },
    tertiary: {
        text: {
            base:  '#1d4ed8',
            hover: '#1d4ed8'
        },
        background: {
            base:  'transparent',
            hover: '#dbeafe'
        }
    }
};
