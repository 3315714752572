export type EmptyObject = Record<string, never>;

export type Cancelable = {
    cancel : () => void,
};

export type Values<Obj> = Obj[keyof Obj];

export type HTTPHeaders = {
    [ key : string ] : string,
};
