import type { PhoneNumber } from '@onetext/api';

export const normalizePhone = (phone : string) : string => {
    return phone.replace(/\D/g, '');
};

export const isValidLocalPhone = (phone : string) : phone is PhoneNumber => {
    return normalizePhone(phone).length === 10;
};

export const isPhoneField = (input : HTMLInputElement) : boolean => {
    const ariaLabel = input.getAttribute('aria-label');

    return (
        input.name.toLowerCase().includes('phone') ||
        input.id.toLowerCase().includes('phone') ||
        (typeof ariaLabel === 'string' && ariaLabel.toLowerCase().includes('phone'))
    );
};
