/** @jsx h */
import type { VNode } from 'preact';

import { Fragment, h } from 'preact';

const LOADING_DOTS_STYLE = `
    .onetext-loading-dots {
        font-size: inherit;
        display: inline-block;
    }

    .onetext-loading-dots .onetext-loading-dot {
        opacity: 0;
        display: inline-block;
        animation-name: loading-dot;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        margin-right: 2px;
    }

    .onetext-loading-dots .onetext-loading-dot:nth-child(1) {
        animation-delay: 0.2s;
    }
    .onetext-loading-dots .onetext-loading-dot:nth-child(2) {
        animation-delay: 0.4s;
    }
    .onetext-loading-dots .onetext-loading-dot:nth-child(3) {
        animation-delay: 0.6s;
    }

    @keyframes loading-dot {
        0% {
            opacity: 0;
        }
        20% {
            opacity: 1;
        }
        30% {
            opacity: 1;
        }
        40% {
            opacity: 0;
        }
        100% {
            opacity: 0;
        }
    }
`;

export const LoadingDots = () : VNode => {
    return (
        <Fragment>
            <style>{ LOADING_DOTS_STYLE }</style>
            <div class={ 'onetext-loading-dots' }>
                {
                    [ 0, 1, 2 ].map(i => (
                        <div
                            key={ i }
                            className={ 'onetext-loading-dot' }>{ '•' }
                        </div>
                    ))
                }
            </div>
        </Fragment>
    );
};
