import type { CallAPIResult } from './api';

import { callOneTextAPI } from './api';

import type { PostShopifyViewProductAPI } from '@onetext/api';
import { HTTP_METHOD } from '@onetext/api';

export const sendShopifyProductViewWebhook = (
    body : PostShopifyViewProductAPI['request']
) : Promise<CallAPIResult<PostShopifyViewProductAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'shopify/webhook/client/product/view',
        body
    });
};
