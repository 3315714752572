import { eventEmitter } from '../lib';

import type { Email, PhoneNumber } from '@onetext/api';

export const onPopupEmailSubmit = eventEmitter<{
    email : Email,
}>();

export const onPopupPhoneSubmit = eventEmitter<{
    phone : PhoneNumber,
}>();

export const events = {
    popup: {
        email: {
            submit: onPopupEmailSubmit
        },
        phone: {
            submit: onPopupPhoneSubmit
        }
    }
};
