import { BRAND_SDK_ATTRIBUTE, BRAND_SDK_QUERY, QUERY_PARAM } from '../constants';

import { getURLQueryParam } from './dom';
import { isDevelopment } from './sdk';

export const getBrandSDKAttribute = (script : HTMLScriptElement, name : string) : string | undefined => {
    const value = script.getAttribute(name);
    return value ?? undefined;
};

export const getBrandSDKScriptQueryParams = (script : HTMLScriptElement) : URLSearchParams => {
    const scriptSrc = script.getAttribute('src');

    if (!scriptSrc) {
        return new URLSearchParams();
    }

    let url : URL;

    if ((/^https?:\/\//).test(scriptSrc)) {
        url = new URL(scriptSrc);
    } else if (isDevelopment() && scriptSrc.startsWith('/')) {
        url = new URL(scriptSrc, window.location.origin);
    } else {
        return new URLSearchParams();
    }

    return url.searchParams;
};

export const getBrandSDKQueryParam = (script : HTMLScriptElement, name : string) : string | undefined => {
    const queryParams = getBrandSDKScriptQueryParams(script);
    return queryParams.get(name) ?? undefined;
};

export const getBrandSDKPopupID = (script : HTMLScriptElement) : string | undefined => {
    return (
        getURLQueryParam(QUERY_PARAM.POPUP_ID) ??
        getBrandSDKAttribute(script, BRAND_SDK_ATTRIBUTE.POPUP_ID) ??
        getBrandSDKQueryParam(script, BRAND_SDK_QUERY.POPUP_ID)
    );
};
