/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { h } from 'preact';
import { useState } from 'preact/hooks';
import { twMerge as tw } from 'tailwind-merge';

import type { ButtonColors, ButtonStyle } from '../../../types';

import { DEFAULT_BUTTON_COLORS } from '../../../constants';
import { delay } from '../../../lib';
import { BUTTON_ATTRIBUTE, BUTTON_LEVEL, BUTTON_SIZE, BUTTON_STATE } from '../../../types';
import { LoadingDots } from '../../loading-dots';
import { useConsentFormContext, useConsentPopupContext } from '../context';

import type { Milliseconds } from '@onetext/api';

type ConsentPopupButtonProps = {
    children : ComponentChildren,
    className ?: string,
    closePopup ?: boolean,
    submitDelay ?: Milliseconds,
    level ?: BUTTON_LEVEL,
    size ?: BUTTON_SIZE,
};

export const ConsentPopupButton = ({
    children,
    level = BUTTON_LEVEL.PRIMARY,
    size = BUTTON_SIZE.MD,
    className = undefined,
    submitDelay,
    closePopup: closePopupOnPress = false
} : ConsentPopupButtonProps) : VNode => {
    const { closePopup, brandConfig } = useConsentPopupContext();
    const { submit, attemptSubmit, loading: formLoading } = useConsentFormContext();

    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);
    const [ hover, setHover ] = useState(false);
    const [ focus, setFocus ] = useState(false);

    const loading = (
        buttonLoading ||
        formLoading
    );

    const onClick = async (event : Event) : Promise<void> => {
        event.preventDefault();
        event.stopPropagation();

        if (closePopupOnPress) {
            closePopup();
        }

        if (!attemptSubmit()) {
            return;
        }

        setButtonLoading(true);
        setDisabled(true);

        if (submitDelay) {
            await delay(submitDelay);
        }

        await submit();

        setButtonLoading(false);
    };

    const onMouseEnter = () : void => setHover(true);
    const onMouseLeave = () : void => setHover(false);
    const onFocus = () : void => setFocus(true);
    const onBlur = () : void => setFocus(false);

    const getColorValue = (
        buttonLevel : keyof ButtonColors,
        attribute : BUTTON_ATTRIBUTE,
        state : BUTTON_STATE
    ) : string => {
        const colors = brandConfig?.button?.colors ?? DEFAULT_BUTTON_COLORS;

        return colors[buttonLevel]?.[attribute]?.[state] ??
            DEFAULT_BUTTON_COLORS[buttonLevel]?.[attribute]?.[state] ??
            '';

    };

    const getLevelStyle = (
        buttonLevel : keyof ButtonColors
    ) : {
        color : string,
        background : string,
        borderColor : string,
    } => {
        const baseBackgroundColor = getColorValue(buttonLevel, BUTTON_ATTRIBUTE.BACKGROUND, BUTTON_STATE.BASE);
        const hoverBackgroundColor = getColorValue(buttonLevel, BUTTON_ATTRIBUTE.BACKGROUND, BUTTON_STATE.HOVER);
        const baseTextColor = getColorValue(buttonLevel, BUTTON_ATTRIBUTE.TEXT, BUTTON_STATE.BASE);
        const hoverTextColor = getColorValue(buttonLevel, BUTTON_ATTRIBUTE.TEXT, BUTTON_STATE.HOVER);
        const baseBorderColor = getColorValue(buttonLevel, BUTTON_ATTRIBUTE.BORDER, BUTTON_STATE.BASE);
        const hoverBorderColor = getColorValue(buttonLevel, BUTTON_ATTRIBUTE.BORDER, BUTTON_STATE.HOVER);
        return {
            color: hover || focus
                ? hoverTextColor
                : baseTextColor,
            background: hover || focus
                ? hoverBackgroundColor
                : baseBackgroundColor,
            borderColor: hover || focus
                ? hoverBorderColor
                : baseBorderColor
        };
    };

    const levelStyle = getLevelStyle(level);

    const baseClassName = 'w-full py-2 min-w-[200px] text-center focus:outline-none';

    let sizeClassName = '';

    switch (size) {
        case BUTTON_SIZE.XS:
            sizeClassName = 'h-8 text-xs';
            break;
        case BUTTON_SIZE.SM:
            sizeClassName = 'h-10 text-sm';
            break;
        case BUTTON_SIZE.MD:
            sizeClassName = 'h-12 text-base';
            break;
        case BUTTON_SIZE.LG:
            sizeClassName = 'h-14 text-lg';
            break;
        case BUTTON_SIZE.XL:
            sizeClassName = 'h-16 text-xl';
            break;
        default:
            sizeClassName = 'h-12 text-base';
            break;
    }

    const buttonStyle : ButtonStyle = brandConfig?.button?.style ?? {};

    return (
        <button
            type={ 'button' }
            onClick={ event => void onClick(event) }
            onMouseEnter={ onMouseEnter }
            onMouseLeave={ onMouseLeave }
            onFocus={ onFocus }
            onBlur={ onBlur }
            disabled={ disabled }
            className={
                tw(
                    baseClassName,
                    sizeClassName,
                    disabled
                        ? 'opacity-[0.35] cursor-wait'
                        : 'cursor-pointer',
                    className
                )
            }
            style={ { ...levelStyle, ...buttonStyle } }
        >
            {
                loading
                    ? <LoadingDots />
                    : children
            }
        </button>
    );
};
