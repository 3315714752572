/** @jsx h */

import type { VNode } from 'preact';

import { h } from 'preact';

import { isValidEmail } from '../../../lib';
import { useConsentFormContext, useConsentPopupContext } from '../context';

import { ConsentPopupTextField } from './text-field';

type ConsentPopupEmailFieldProps = {
    autofocus ?: boolean,
    placeholder ?: string,
    submitOnAutoComplete ?: boolean,
    className ?: string,
};

export const ConsentPopupEmailField = ({
    autofocus,
    placeholder = 'Email Address',
    submitOnAutoComplete = true,
    className
} : ConsentPopupEmailFieldProps) : VNode => {
    const {
        setEmail,
        email
    } = useConsentPopupContext();

    const {
        submit
    } = useConsentFormContext();

    return (
        <ConsentPopupTextField
            type={ 'email' }
            id={ 'email' }
            name={ 'email' }
            autocomplete={ 'email' }
            autofocus={ autofocus }
            placeholder={ placeholder }
            defaultValue={ email }
            onValue={ setEmail }
            onAutoComplete={
                () => {
                    if (submitOnAutoComplete) {
                        void submit();
                    }
                }
            }
            inputPattern={ /^\S*$/ }
            required={ true }
            isValid={ isValidEmail }
            deformatter={ val => val.trim().toLowerCase() }
            className={ className }
        />
    );
};
