import type { ComponentChildren } from 'preact';

import type { COUNTRY } from '@onetext/api';

export type PageChildrenRenderProps = {
    properties : Record<string, string>,
    customerCountry : COUNTRY | undefined,
};

export type PageChildrenRenderFunction = (props : PageChildrenRenderProps) => ComponentChildren;

export type TeaserTextRenderProps = {
    properties : Record<string, string>,
};

export type TeaserTextRenderFunction = (props : TeaserTextRenderProps) => string;
