import type { VNode } from 'preact';

import { h, render as preactRender } from 'preact';

import { ErrorBoundary } from '../components';
import { CLASS_NAME } from '../constants';
import { awaitBody } from '../lib';
import { insertSDKStyleSheetElement } from '../style';

type RenderOptions = {
    container ?: Element,
    element : VNode | JSX.Element | null,
    shadowDOM ?: boolean,
};

export const render = async ({
    container,
    element,
    shadowDOM = true
} : RenderOptions) : Promise<void> => {
    container ??= await awaitBody();

    let finalContainer : Element | ShadowRoot;

    if (shadowDOM) {
        const shadowContainer = document.createElement('div');
        shadowContainer.className = CLASS_NAME.SHADOW_CONTAINER;
        shadowContainer.style.display = 'block';
        container.appendChild(shadowContainer);

        finalContainer = shadowContainer.attachShadow({
            mode: 'open'
        });
    } else {
        finalContainer = container;
    }

    await insertSDKStyleSheetElement({
        container: finalContainer
    });

    const finalElement = h(ErrorBoundary, null, element);

    preactRender(finalElement, finalContainer);
};

export { h as element } from 'preact';
export { useEffect, useState } from 'preact/hooks';

export { default as clsx } from 'clsx';

export { default as deepmerge } from 'deepmerge';
